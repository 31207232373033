import React from 'react'
import { Jumbotron, Container } from 'react-bootstrap'

export default ({ config }) => {
  const { coverColor, imgUrl, title } = config
  const style = {
    backgroundImage: `url("${imgUrl}")`,
    backgroundSize: 'cover',
    backgroundColor: coverColor,
  }

  if (imgUrl) {
    style.minHeight = 450
  }

  return (
    <Jumbotron style={style}>
      <Container>
        <h1 className="bg-white text-dark py-1 px-3 d-inline-block display-4">
          {title}
        </h1>
      </Container>
    </Jumbotron>
  )
}
