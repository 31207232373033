import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import Banner from './banner'

export default function Layout({ bannerConfig, children }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <header>
        <Navbar bg="light" expand="lg">
          <Container>
            <Link to="/">
              <Navbar.Brand>{data.site.siteMetadata.title}</Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto" as="ul">
                <Nav.Item as="li">
                  <Link className="nav-link" activeClassName="active" to="/">
                    Home
                  </Link>
                </Nav.Item>
                <NavDropdown title="Media" id="nav-dropdown">
                  <NavDropdown.Item as="li">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/books"
                    >
                      Books
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item as="li">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/games"
                    >
                      Games
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item as="li">
                    <Link
                      className="nav-link"
                      activeClassName="active"
                      to="/movies"
                    >
                      Movies
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Item as="li">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/projects"
                  >
                    Projects
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/2020-goals"
                  >
                    2020 Goals
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/recipes"
                  >
                    Recipes
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/checklists"
                  >
                    Checklists
                  </Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      {bannerConfig && <Banner config={bannerConfig} />}
      <main>
        <Container className="mt-3 mb-5">{children}</Container>
      </main>
    </>
  )
}
